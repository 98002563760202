import { Button, Theme } from "@mui/material";
import theme from "../../styles/theme";
import { Link } from "react-router-dom";
import { sizing } from "../../styles/sizing";
import { isExternalUrl } from "../../utils/isExternalUrl";
import styled from "@emotion/styled";
import { css } from "@emotion/css";

const primaryStyles = (theme: Theme) => ({
  root: {
    transition: "all 0.4s ease",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    background: theme.palette.primary.main,
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    borderRadius: "200px",
    textDecoration: "none",

    "&:hover, &:focus": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.main,
      boxShadow: theme.shadows[2],
    },

    "&.Mui-disabled": {
      background: theme.palette.primary.main,
      color: theme.palette.grey[700],
    },
  },
});

const secondaryStyles = (theme: Theme) => ({
  root: {
    transition: "all 0.4s ease",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[600],
    color: theme.palette.primary.main,
    background: "white",
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    borderRadius: "200px",
    textDecoration: "none",

    "&:hover, &:focus": {
      color: theme.palette.primary.main,
      background: "white",
      boxShadow: `inset ${theme.shadows[2]}`,
    },

    "&.Mui-disabled": {
      background: "white",
      color: theme.palette.grey[700],
    },
  },
});

const textStyles = (theme: Theme) => ({
  root: {
    transition: "all 0.4s ease",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "transparent",
    color: theme.palette.grey[800],
    background: "transparent",
    padding: `${theme.spacing(1.5)} ${theme.spacing(3)}`,
    borderRadius: "200px",
    textDecoration: "none",

    "&:hover": {
      color: theme.palette.grey[800],
      background: theme.palette.grey[300],
    },

    "&:focus": {
      color: theme.palette.grey[800],
      background: theme.palette.grey[500],
      boxShadow: theme.shadows[2],
    },

    "&:active": {
      color: theme.palette.grey[800],
      background: "transparent",
    },

    "&.Mui-disabled": {
      background: "transparent",
      color: theme.palette.grey[500],
    },
  },
});

const pillStyles = (theme: Theme) => ({
  root: {
    transition: "all 0.4s ease",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.grey[600],
    color: theme.palette.grey[900],
    background: "transparent",
    padding: `6px 12px`,
    borderRadius: "200px",
    fontWeight: 400,
    textDecoration: "none",

    "&:hover": {
      color: theme.palette.primary.main,
      background: theme.palette.grey[200],
    },

    "&:focus": {
      borderColor: theme.palette.primary.main,
    },

    "&:active": {
      boxShadow: `inset ${theme.shadows[2]}`,
    },
  },
});

const linkStyles = {
  text: css({
    fontSize: sizing(14),
    fontWeight: 600,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    textTransform: "none",
    textDecoration: "none",
    fontFamily: theme.typography.fontFamily,
  }),
};

export const PrimaryButton = styled(Button)`
  ${primaryStyles(theme).root}
`;

export const SecondaryButton = styled(Button)`
  ${secondaryStyles(theme).root}
`;

export const TextButton = styled(Button)`
  ${textStyles(theme).root}
`;

export const PillButton = styled(Button)`
  ${pillStyles(theme).root}
`;

const StyledLink = ({
  classes,
  to,
  className,
  ...props
}: {
  classes: { root: string };
  to: string;
  className?: string;
}) => {
  return (
    <Link className={`${linkStyles.text}  ${className}`} to={to} {...props} />
  );
};

export const PrimaryLink = styled((props: any) => {
  return isExternalUrl(props.to) ? (
    <a {...props} href={props.to} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  ) : (
    <StyledLink {...props}>{props.children}</StyledLink>
  );
})`
  ${primaryStyles(theme).root}
`;

export const SecondaryLink = styled((props: any) =>
  isExternalUrl(props.to) ? (
    <a {...props} href={props.to} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  ) : (
    <StyledLink {...props}>{props.children}</StyledLink>
  )
)`
  ${secondaryStyles(theme).root}
`;

export const TextLink = styled((props: any) =>
  isExternalUrl(props.to) ? (
    <a {...props} href={props.to} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  ) : (
    <StyledLink {...props}>{props.children}</StyledLink>
  )
)`
  ${textStyles(theme).root}
`;

export const PillLink = styled((props: any) =>
  isExternalUrl(props.to) ? (
    <a {...props} href={props.to} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  ) : (
    <StyledLink {...props}>{props.children}</StyledLink>
  )
)`
  ${pillStyles(theme).root}
`;
