import React from "react";

export function LightningIcon(props: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) {
  return (
    <svg {...props} fill="none" viewBox="0 0 14 24">
      <path
        d="M5.66666 24H4.33332L5.66666 14.6667H0.999989C0.226655 14.6667 0.239989 14.24 0.493322 13.7867C0.746655 13.3333 0.559989 13.68 0.586655 13.6267C2.30666 10.5867 4.89332 6.05333 8.33332 0H9.66666L8.33332 9.33333H13C13.6533 9.33333 13.7467 9.77333 13.6267 10.0133L13.5333 10.2133C8.27999 19.4 5.66666 24 5.66666 24Z"
        fill="currentColor"
      />
    </svg>
  );
}
