import {
  makeStyles,
  Theme,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import { PrimaryButton } from "../Button";
import { Modal } from "../Modal";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useGetUser } from "../../contexts/userContext";
import { useGetCopy } from "../../api/queries/copy";
import { composeResource } from "../../utils/composeResource";
import theme from "../../styles/theme";
import { css } from "@emotion/css";

const classes = {
  container: css({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(8),
  }),
  drawer: css({
    zIndex: 3000,
  }),
  input: css({
    border: "none",
    background: "transparent",
    flex: 1,
    "&:focus": {
      outline: "none",
    },
  }),
  formSpacing: css({
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }),
  loader: css({
    color: "white",
    width: "24px !important",
    height: "24px !important",
  }),
};

export function CashbackPanel({
  openmod,
  onClose,
}: {
  openmod: boolean;
  onClose: () => void;
}) {
  const { acceptCashbackTnCs } = useGetUser();

  const { data: copyData } = useGetCopy({ key: "global.cashback" });
  const { getText } = composeResource(copyData);

  const onSubmit = async () => {
    try {
      window.localStorage.setItem(
        "userCashBackTermsVersion",
        getText("cashback.terms_version") || ""
      );
      let res = await acceptCashbackTnCs(getText("cashback.terms_version"));
      if (res) {
        const link = res.data.content.ssoUrl;
        setTimeout(() => {
          window.open(link);
        });
      }
    } catch (error) {
      //console.log(error);
    }
    onClose();
  };

  const ModalFooter = () => {
    return (
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={{
          terms: false,
        }}
        validationSchema={yup.object().shape({
          terms: yup
            .bool()
            .oneOf([true], getText("cashback.form.error.consent_to_terms")),
        })}
        validateOnBlur
        validateOnChange
      >
        {({ values, setFieldValue, errors, isSubmitting, isValid }) => {
          return (
            <Form>
              <FormControl component="fieldset">
                <FormGroup>
                  <FormControlLabel
                    className={classes.formSpacing}
                    control={
                      <Checkbox
                        disabled={isSubmitting}
                        onChange={(e) =>
                          setFieldValue("terms", e.target.checked)
                        }
                        name="terms"
                        checked={values.terms}
                      />
                    }
                    label={
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            getText("cashback.i_accept") +
                            " " +
                            getText("cashback.termsandconditions_link") +
                            " and " +
                            getText("cashback.privacy_policy_link"),
                        }}
                      />
                    }
                  />
                  {errors && errors.terms && (
                    <Box pb={2} mt={-2}>
                      <Typography color="error" variant="body1">
                        {errors.terms}
                      </Typography>
                    </Box>
                  )}
                </FormGroup>
                <FormGroup>
                  <PrimaryButton
                    disabled={isSubmitting || !isValid}
                    type="submit"
                  >
                    {isSubmitting ? (
                      <CircularProgress className={classes.loader} />
                    ) : (
                      getText("cashback.accept_button")
                    )}
                  </PrimaryButton>
                </FormGroup>
              </FormControl>
            </Form>
          );
        }}
      </Formik>
    );
  };

  return (
    <Modal
      open={openmod}
      onClose={onClose}
      title={getText("cashback.modal_title")}
      ariaDescribedby={getText("cashback.modal_title")}
      footer={<ModalFooter />}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: getText("cashback.terms_description"),
        }}
      />
    </Modal>
  );
}
