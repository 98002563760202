import React from "react";

export function AdminIcon(props: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) {
  return (
    <svg {...props} viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.4999 24.9303C26.4278 24.9303 27.1799 24.1782 27.1799 23.2503C27.1799 22.3225 26.4278 21.5703 25.4999 21.5703C24.5721 21.5703 23.8199 22.3225 23.8199 23.2503C23.8199 24.1782 24.5721 24.9303 25.4999 24.9303Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5 26.25C24.405 26.25 22.215 26.79 22.14 27.87C22.89 28.935 24.12 29.625 25.5 29.625C26.88 29.625 28.11 28.935 28.86 27.87C28.785 26.79 26.595 26.25 25.5 26.25Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 16.635V9.405L15.75 4.5L4.5 9.405V16.77C4.5 23.58 9.3 29.955 15.75 31.5C16.575 31.305 17.37 31.02 18.15 30.675C19.77 32.985 22.455 34.5 25.5 34.5C30.465 34.5 34.5 30.465 34.5 25.5C34.5 21.045 31.26 17.355 27 16.635ZM16.5 25.5C16.5 26.34 16.62 27.165 16.845 27.93C16.485 28.095 16.125 28.26 15.75 28.38C10.995 26.88 7.5 22.02 7.5 16.77V11.37L15.75 7.77L24 11.37V16.635C19.74 17.355 16.5 21.045 16.5 25.5ZM25.5 31.5C22.185 31.5 19.5 28.815 19.5 25.5C19.5 22.185 22.185 19.5 25.5 19.5C28.815 19.5 31.5 22.185 31.5 25.5C31.5 28.815 28.815 31.5 25.5 31.5Z"
        fill="currentColor"
      />
    </svg>
  );
}
