import { basketItem } from "../contexts/cartContext";

export const calculateTotals = (discount: number, value: number) => {
  const saving = value * (discount / 100);
  return {
    saving: new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(saving),
    total: new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(value - saving),
  };
};

export const calculateRawTotals = (discount: number, value: number) => {
  const saving = value * (discount / 100);
  return {
    saving: saving,
    total: value - saving,
  };
};

export const formatCurrency = (value: number): string => {
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
  })
    .format(value)
    .replace(/\D00(?=\D*$)/, "");
};

export const calculateBasket = (basket: basketItem[], delivery?: number) => {
  const savings = basket.reduce(
    (acc, cur) => acc + cur.amount * (cur.contract.discountPct / 100),
    0
  );
  const totals =
    basket.reduce((acc, cur) => {
      // @ts-ignore
      return acc + parseInt(cur.amount, 10);
    }, 0) - savings;
  const subTotals =
    basket.reduce((acc, cur) => {
      //@ts-ignore
      return acc + parseInt(cur.amount, 10);
    }, 0) - savings;

  return {
    total:
      delivery && delivery > 0
        ? formatCurrency(totals + delivery)
        : formatCurrency(totals),
    delivery: delivery && delivery > 0 ? formatCurrency(delivery) : "Free",
    subTotal: formatCurrency(subTotals),
    saving: formatCurrency(savings),
    raw: {
      total: delivery && delivery > 0 ? totals + delivery : totals,
      delivery: delivery && delivery > 0 ? delivery : 0,
      subTotal: subTotals,
      saving: savings,
    },
  };
};
