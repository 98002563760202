import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { offerSearch } from "../../types/generated/offerSearch";

export const OFFER_SEARCH_QUERY = gql`
  query offerSearch($search: String!, $ids: [String!]) {
    offerCollection(
      limit: 4
      where: {
        OR: [
          { name_contains: $search }
          { description_contains: $search }
          { brandDescription_contains: $search }
        ]
        mmiId_in: $ids
      }
    ) {
      items {
        name
        mmiId
        logo {
          url
          title
        }
        sys {
          id
        }
        featured
        inStore
        fastSelling
        isNew
        reloadable
        featuredImagesCollection(limit: 1) {
          items {
            url
            title
            description
          }
        }
      }
    }
  }
`;

export const useGetOfferSearch = (args: QueryHookOptions<offerSearch>) =>
  useQuery<offerSearch>(OFFER_SEARCH_QUERY, args);
