import {
  from,
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";

const cache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTUL_SPACE}/environments/${process.env.REACT_APP_CONTENFUL_ENVIRONMENT}`,
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: any) => ({
    headers: {
      authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_TOKEN}`, // however you get your token
      ...headers,
    },
  }));
  return forward(operation);
});

const link = from([authLink, httpLink]);

export const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    query: {
      fetchPolicy: "cache-first",
    },
  },
});
