export function isExternalUrl(url: string) {
  const host = window.location.hostname;

  const linkHost = (function (url) {
    if (/^https?:\/\//.test(url)) {
      var parser = document.createElement("a");
      parser.href = url;
      return parser.hostname;
    } else {
      return window.location.hostname;
    }
  })(url);

  return host !== linkHost;
}
