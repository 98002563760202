import React, { ReactNode } from "react";
import {
  Divider,
  Grid,
  Typography,
  Hidden,
  SwipeableDrawer,
  Box,
  Slide,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { ChevronRight } from "@mui/icons-material";
import { classes } from "./styles";
import { grey } from "@mui/material/colors";

export function MobilePanel({
  open,
  onClose,
  onOpen,
  onOpenAccount,
  onOpenShop,
  logo,
  logoAlt,
  secondaryLogo,
  secondaryLogoAlt,
  shopActive,
  accountActive,
  shopPanel,
  accountPanel,
  copy,
  shopRouteActive,
  accountRouteActive,
  hasAccessToCashBack,
  callCashback,
}: mobilePanelNavigationProps) {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <Hidden mdUp>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={onClose}
        onOpen={onOpen}
        disableDiscovery={iOS}
        className={classes.mobileMenu}
        role="navigation"
        aria-label="Navigation menu"
      >
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Box
              display="flex"
              borderBottom={`1px solid ${grey[300]}`}
              width="100%"
            >
              <Box height="56px" padding={2} display="flex" alignItems="center">
                <img className={classes.logo} src={logo} alt={logoAlt} />
              </Box>
              <Box height="56px" padding={2} display="flex" alignItems="center">
                {secondaryLogo && (
                  <img
                    className={classes.logo}
                    src={secondaryLogo}
                    alt={secondaryLogoAlt}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.paddingLeftZero}>
            <Grid
              container
              spacing={0}
              className={`${classes.linksList} ${classes.paddingZero}`}
              direction="column"
            >
              <Grid item xs={12}>
                <NavLink
                  className={({ isActive }) =>
                    `${classes.navLink} ${classes.mobilePanelMargin}` +
                    (isActive ? " active" : "")
                  }
                  end
                  to="/"
                >
                  <Typography variant="h5">{copy["home"]}</Typography>
                </NavLink>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item>
                <button
                  aria-label="Shop Menu"
                  onClick={onOpenShop}
                  aria-owns={shopActive ? "shop-menu" : undefined}
                  aria-haspopup="true"
                  className={`${classes.navLink} ${classes.mobilePanelMargin} ${
                    shopRouteActive && "active"
                  }`}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingRight={1}
                  >
                    <Typography variant="h5">{copy.shop}</Typography>
                    <ChevronRight color="primary" />
                  </Box>
                </button>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item>
                <button
                  aria-label="Account Menu"
                  className={`${classes.navLink} ${classes.mobilePanelMargin} ${
                    accountRouteActive && "active"
                  }`}
                  onClick={onOpenAccount}
                  aria-owns={accountActive ? "account-menu" : undefined}
                  aria-haspopup="true"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    paddingRight={1}
                  >
                    <Typography variant="h5">{copy.account}</Typography>
                    <ChevronRight color="primary" />
                  </Box>
                </button>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item>
                <NavLink
                  className={({ isActive }) =>
                    `${classes.navLink} ${classes.mobilePanelMargin}` +
                    (isActive ? " active" : "")
                  }
                  end
                  to="/favourites"
                >
                  <Typography variant="h5">{copy.favourites}</Typography>
                </NavLink>
                <Divider className={classes.divider} />
              </Grid>
              {hasAccessToCashBack && (
                <Grid item>
                  <button
                    onClick={() => {
                      callCashback();
                      onClose();
                    }}
                    className={`${classes.navLink} ${classes.mobilePanelMargin} `}
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      paddingRight={1}
                    >
                      <Typography variant="h5">{copy.cashback}</Typography>
                    </Box>
                  </button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Slide in={shopActive} direction="left">
          <div className={classes.mobilePanel}>{shopPanel}</div>
        </Slide>
        <Slide in={accountActive} direction="left">
          <div className={classes.mobilePanel}>{accountPanel}</div>
        </Slide>
      </SwipeableDrawer>
    </Hidden>
  );
}

export interface mobilePanelNavigationProps {
  shopRouteActive: boolean;
  accountRouteActive: boolean;
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  onOpenShop: () => void;
  onOpenAccount: () => void;
  callCashback: () => void;
  logo: string;
  logoAlt: string;
  secondaryLogo: string;
  secondaryLogoAlt: string;
  shopActive: boolean;
  accountActive: boolean;
  accountPanel: ReactNode;
  shopPanel: ReactNode;
  hasAccessToCashBack: boolean;
  copy: {
    home: string;
    shop: string;
    cashback: string;
    favourites: string;
    account: string;
  };
}
