import React from "react";
import { Container, Typography, Grid, makeStyles, Theme } from "@mui/material";
import { LinkSet } from "../../utils/composeResource";
import theme from "../../styles/theme";
import { css } from "@emotion/css";

const classes = {
  wrapper: css({
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  }),
  title: css({
    paddingBottom: theme.spacing(2),
  }),
  logo: css({
    width: "120px",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: "200px",
      marginBottom: theme.spacing(3),
    },
  }),
};

export interface FooterProps {
  column1Links: LinkSet[];
  column2Links: LinkSet[];
  copy: {
    column1title: string;
    column2title: string;
    legalCopy: string;
  };
  logo: string;
}

const Footer = ({ column1Links, column2Links, copy, logo }: FooterProps) => {
  return (
    <Container component="footer" className={classes.wrapper}>
      <Grid spacing={4} container justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography
            className={classes.title}
            variant="subtitle2"
            gutterBottom
          >
            {copy.column1title}
          </Typography>

          {column1Links.map((link, index) => (
            <a
              key={`footer-link-column1--${index}`}
              aria-label={link.ariaLabel}
              title={link.ariaLabel}
              href={link.url}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Typography variant="body1" gutterBottom>
                {link.label}
              </Typography>
            </a>
          ))}
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            className={classes.title}
            variant="subtitle2"
            gutterBottom
          >
            {copy.column2title}
          </Typography>
          {column2Links.map((link, index) => (
            <a
              key={`footer-link-column1--${index}`}
              aria-label={link.ariaLabel}
              title={link.ariaLabel}
              href={link.url}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Typography variant="body1" gutterBottom>
                {link.label}
              </Typography>
            </a>
          ))}
        </Grid>
        <Grid item xs={12}>
          <img loading="lazy" className={classes.logo} alt="logo" src={logo} />
          <Typography variant="body2" gutterBottom>
            {copy.legalCopy}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
