import React, { useContext, useEffect, useRef, useState } from "react";
import axios, { AxiosPromise } from "axios";
import sha256 from "crypto-js/sha256";
import base64 from "crypto-js/enc-base64";
import { stringify, parse } from "qs";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Contract } from "./cartContext";
import publicIp from "public-ip";

type CustomerContract = {
  extensionData: null;
  handlingFee: number;
  isSwapSuggestionActivated: boolean;
  minimumOrder: number;
  minimumOrderForDiscount: number;
  payCardFee: boolean;
  payPostageFee: boolean;
};

type Product = {
  cardExternalRef: null | string;
  cardId: null | number;
  contractSupplierId: number;
  discountPercentage: number;
  eBSupplierId: number;
  "fixedValue:": null;
  inputPrice: number;
  isInstantDelivery: boolean;
  isPostageRequired: boolean;
  parentId: string;
  setUpCost: number;
  supplierTypeId: number;
  voucherValue: string;
  quantity: number;
  total: number;
  totalValue: number;
};

type ProductGroup = {
  groupSupplierId: number;
  groupSupplierName: string;
  products: Product[];
  ProductGroupTotalDiscount: number;
  ProductGroupTotalAmount: number;
  ProductGroupTotalDiscountInPercent: number;
  ProductGroupTotalAmountWithDiscount: number;
};

type Order = {
  memberId: string;
  companyId: number;
  ssvCorporateCustomerId: string;
  ssvUserId: string;
  userAgent: string;
  userIP: string;
  "cardTypeId ": string;
  isCreditCard: boolean;
  isSavedCard: boolean;
  savedCardToken: string;
  canSaveCard: string;
  postageId: string;
  customerContract: CustomerContract;
  deliveryEmailAddress: string;
  deliveryPhoneNumber: string;
  productGroups: ProductGroup[];
  addresses: Address[];
  orderSourceId: number;
};

type Address = {
  addressId?: number;
  addressFirstName: string;
  addressLastName: string;
  address1: string;
  address2: string;
  city: string;
  county: string;
  postCode: string;
  phoneNumber: string;
  addressType: number;
  addressTitle: string;
};

type UpdateAddressParams = {
  AddressId?: number;
  AddressFirstName: string;
  AddressLastName: string;
  address1: string;
  address2?: string;
  city: string;
  county: string;
  postcode: string;
  phoneNumber: string;
  addressType: number;
};

type DeleteAddressParams = {
  AddressId: string;
  memberId: string;
};

type ConfirmationData = {
  voad: string;
  ReqURLQuery: string;
  ReqURL: string;
};

type Activity = {
  activity: "PAGE_LOAD" | "ADD_BASKET" | "REMOVE_BASKET" | "LOGIN";
  identifier?: string;
  page: string;
};

type UpdateNameParams = {
  firstName: string;
  lastName: string;
  memberId: string;
};

type UpdatePasswordParams = {
  companyId: string;
  memberId: string;
  originalPassword: string;
  newPassword: string;
};

type SubscribeParams = {
  subscribe: boolean;
  emailAddressId: number;
};

type UnsubscribeParams = {
  subscribe: boolean;
  emailAddressId: number;
};

type UpdateEmailParams = {
  emailAddressType: number;
  newEmail: string;
};

type SubmitCompetitionParams = {
  competitionQuestionID: number;
  competitionAnswerID: number;
};

type UpdateCardParams = {
  cardId: number;
  cardNumber: string;
  esSupplierId: number;
  esSupplierName: string;
  friendlyName: string;
  imageURL: string;
  isVisible: boolean;
};

type UpdateReminderParams = {
  topUpReminderId: number;
  delete: boolean;
  frequencyType: number;
  frequencyValue: number;
  Amount: number;
  Email: string;
};

type AddReminderParams = {
  userId: number;
  cardId: number;
  frequencyType: number;
  frequencyValue: number;
  Amount: number;
  Email: string;
};

interface UserContextValue {
  data: UserData;
  loading: boolean;
  updateAddress: (params: UpdateAddressParams) => Promise<any>;
  deleteAddress: (params: DeleteAddressParams) => Promise<any>;
  updateName: (params: UpdateNameParams) => Promise<any>;
  updatePassword: (params: UpdatePasswordParams) => Promise<any>;
  addFavourite: (id: string) => Promise<any>;
  removeFavourite: (id: string) => Promise<any>;
  subscribe: (params: SubscribeParams) => Promise<any>;
  unSubscribe: (params: UnsubscribeParams) => Promise<any>;
  updateCard: (params: UpdateCardParams) => Promise<any>;
  updateEmail: (params: UpdateEmailParams) => Promise<any>;
  submitCompetition: (params: SubmitCompetitionParams) => Promise<any>;
  getCineworld: () => Promise<any>;
  getUniqodo: (code: string) => Promise<any>;
  refreshToken: () => Promise<any>;
  updateReminder: (params: UpdateReminderParams) => Promise<any>;
  addReminder: (params: AddReminderParams) => Promise<any>;
  deleteReminder: (params: { topUpReminderId: number }) => Promise<any>;
  placeOrder: (params: Order) => Promise<any>;
  confirmOrder: (params: ConfirmationData) => Promise<any>;
  logActivity: (params: Activity) => Promise<any>;
  getTermsAndConditions: (esSupplierOfferId: number) => Promise<string>;
  userAcceptedCashbackTnCs: () => Promise<any>;
  acceptCashbackTnCs: (cashBackTermsVersion: string) => Promise<any>;
  getOrderHistory: () => Promise<any>;
}

export const UserContext = React.createContext<UserContextValue>({
  data: {},
  loading: false,
  updateAddress: () => new Promise(() => null),
  deleteAddress: () => new Promise(() => null),
  updateName: () => new Promise(() => null),
  updatePassword: () => new Promise(() => null),
  addFavourite: () => new Promise(() => null),
  removeFavourite: () => new Promise(() => null),
  subscribe: () => new Promise(() => null),
  unSubscribe: () => new Promise(() => null),
  updateCard: () => new Promise(() => null),
  updateEmail: () => new Promise(() => null),
  refreshToken: () => new Promise(() => null),
  submitCompetition: () => new Promise(() => null),
  getCineworld: () => new Promise(() => null),
  getUniqodo: () => new Promise(() => null),
  updateReminder: () => new Promise(() => null),
  addReminder: () => new Promise(() => null),
  deleteReminder: () => new Promise(() => null),
  placeOrder: () => new Promise(() => null),
  confirmOrder: () => new Promise(() => null),
  logActivity: () => new Promise(() => null),
  getTermsAndConditions: () => new Promise(() => null),
  userAcceptedCashbackTnCs: () => new Promise(() => null),
  acceptCashbackTnCs: () => new Promise(() => null),
  getOrderHistory: () => new Promise(() => null),
});

const generateCodeChallenge = () => {
  // code challenge is sha256 encrypted
  // code verifier / password on basic auth is non sha 256 base 64 string
  const random = Math.random().toString(36).substring(0, 8);

  const urlEncode = function (unencoded: string) {
    return base64
      .parse(unencoded)
      .toString()
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/g, "");
  };

  const code_verifier = urlEncode(random);
  const code_challenge = sha256(code_verifier)
    .toString(base64)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/g, "");

  return {
    code_verifier,
    code_challenge,
  };
};

export interface Catalogue {
  offers?: Offer[];
  categories: { categoryId: number }[];
  ids?: string[];
  cats?: string[];
  competitions: Competition[];
  contract: {
    ssvCorporateCustomerId: number;
    ssvUserId: number;
    extensionData: null;
    handlingFee: number;
    isSwapSuggestionActivated: boolean;
    minimumOrder: number;
    minimumOrderForDiscount: number;
    payCardFee: boolean;
    payPostageFee: boolean;
  };
  userCards: userCard[];
}

export type userCard = {
  cardId: number;
  cardNumber: string;
  esSupplierId: number;
  esSupplierName: string;
  friendlyName: string;
  imageURL: string;
  isVisible: boolean;
  cardSupplierId: number;
  topUpAmount: number;
  topUpReminderId: number;
  topUpFrequencyTypeId: number;
  topUpFrequencyValue: number;
  topUpReminderEmailAddress: string | null;
};

interface Competition {
  competitionId: number;
  drawDate: string;
  endDate: string;
  esSupplierId: number;
  image: string;
  longDescription: string;
  prize: string;
  questions: string;
  questionsAnswered: number;
  redemptionDate: string;
  shortDescription: string;
  startDate: string;
  termsAndConditions: string;
  title: string;
  answers: string;
}

interface UserData {
  memberId?: string;
  catalogue?: Catalogue;
  member?: {
    firstName: string;
    lastName: string;
    companyLogo: string;
    companyBrandLogo: string;
    last12MonthsSavings: number;
    logOutURL: string;
    hasAccessToCashBack?: boolean;
    cashBackTermsVersion?: string;
    memberCompanyLocation: {
      company: {
        companyId: number;
        companyName: string;
        passwordConfiguration: {
          minLength: number;
          maxLength: number;
        };
      };
    };
    emailAddresses: {
      emailAddressId: number;
      emailAddress: string;
      preferredForContact: boolean;
      isUsedForHotOffers: boolean;
      emailAddressType: 1 | 2 | 3;
    }[];
  };
  payment?: {
    addresses: DeliveryAddress[];
    savedCards: savedCard[];
    postageTypes: {
      description: string;
      externalDescription: string;
      externalReference: string;
      fee: 0;
      name: string;
      postageTypeId: number;
    }[];
  };
}

export interface savedCard {
  cardType: string;
  cardholderName: string;
  cardTypeId: number;
  cardTypeString: string;
  createdOn: string;
  expiryClassName: string;
  expiryDate: string;
  isExpired: boolean;
  lastFourDigits: string;
  token: string;
}

export interface Offer {
  esSupplierId: number;
  esSupplierOfferId: number;
  isFavourite: boolean;
  imageOfferESURL: string;
  ssvRetailerVouchers?: Contract[];
  esDiscountPercentage?: number;
  termsAndConditions?: string;
  esHowToRedeem?: string;
  esMediumDescription?: string;
  esLongDescription?: string;
  esOfferExternalURL?: string;
  esUniqueOfferCode?: string;
  esDetailPageURL?: string;
  esShortDescription?: string;
  esRedemptionCode?: string;
  categories: number[];
  subCategories: number[];
}

export interface DeliveryAddress {
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  addressType?: number;
  city?: string;
  county?: string;
  deliveryAddressFirstName?: string;
  deliveryAddressId?: number;
  deliveryAddressLastName?: string;
  phoneNumber?: string;
  postcode?: string;
  state?: string;
  isNew: boolean;
}

export const UserProvider = ({
  children,
  testingValue,
}: React.PropsWithChildren<{ testingValue?: UserContextValue }>) => {
  const base = process.env.REACT_APP_ES_API;
  const appId = process.env.REACT_APP_ID;

  const [value, setValue] = useState<{
    loading: boolean;
    error: any;
    data: UserData;
  }>({
    data: {},
    loading: true,
    error: null,
  });

  const failedRefreshTokenRequestCount = useRef<number>(0);
  const failedAuthTokenRequestCount = useRef<number>(0);

  const [userIP, setUserIP] = useState<string>();
  const [isSetup, setIsSetup] = useState(false);

  const { code_verifier, code_challenge } = generateCodeChallenge();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!testingValue) {
      const searchParams = parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (searchParams.token2 && searchParams.token1) {
        setup(
          searchParams.token2.toString(),
          searchParams.token1.toString()
        ).then(() => {
          if (searchParams.ess) {
            navigate(`/offer/${searchParams.ess}`);

            return;
          }

          if (searchParams.ddc) {
            navigate(`/campaign/${searchParams.ddc}`);

            return;
          }

          navigate("/");
          setIsSetup(true);
        });
      } else {
        refreshData();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSetup) {
      logActivity({ activity: "LOGIN", page: "home" });
    }
    // eslint-disable-next-line
  }, [isSetup]);

  if (testingValue) {
    return (
      <UserContext.Provider value={testingValue}>
        {children}
      </UserContext.Provider>
    );
  }

  const setup = (username: string, password: string) => {
    return axios({
      url: `${base}api/v2/ebservices/esauthentication/authenticateonetime`,
      method: "POST",
      data: {
        username: username,
        password: password,
        codechallenge: code_challenge,
        version: "1.5.0.0",
      },
      headers: {
        "content-type": "application/json",
      },
    })
      .then((data) => {
        return authorize(
          data?.data?.content?.MemberId,
          data?.data?.content?.AuthorizationCode
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const authorize = (id: string, pass: string): Promise<any> => {
    if (failedAuthTokenRequestCount.current > 4) return logOut();

    failedAuthTokenRequestCount.current =
      failedAuthTokenRequestCount.current + 1;
    return axios({
      url: `${base}token`,
      method: "POST",
      data: stringify({
        username: id || "",
        password: pass,
        grant_type: "password",
        version: "1.5.0.0",
      }),
      auth: {
        username: appId || "",
        password: code_verifier,
      },
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
      .then((response) => {
        window.localStorage.setItem("access_token", response.data.access_token);
        window.localStorage.setItem(
          "refresh_token",
          response.data.refresh_token
        );
        window.localStorage.setItem("member_id", response.data.memberId);
        // @ts-ignore
        window.gtag("config", process.env.REACT_APP_GA_ID, {
          user_id: response.data.memberId,
        });
        failedAuthTokenRequestCount.current = 0;
        setValue((previousValue) => ({
          ...previousValue,
          data: { memberId: response.data.memberId, ...previousValue.data },
        }));
        return refreshData();
      })
      .catch((err) => {
        console.error(err);
        return refreshToken();
      });
  };

  const logOut = async () => {
    const logOutURL =
      value.data.member?.logOutURL || process.env.REACT_APP_LOGOUT_URL || "";
    window.localStorage.removeItem("access_token");
    window.localStorage.removeItem("refresh_token");
    window.localStorage.removeItem("member_id");
    if (logOutURL) window.location.replace(logOutURL);
  };

  const refreshData = (): Promise<any> => {
    const accessTokenString = window.localStorage.getItem("access_token");
    const refreshTokenString = window.localStorage.getItem("refresh_token");
    if (!accessTokenString) {
      if (!refreshTokenString) return logOut();
      return refreshToken().then(() => refreshData());
    }

    return Promise.all([getCatalogue(), getMember(), getPayment(), getIp()])
      .then((data) => {
        if (data.some((i: any) => i && i.status === 200)) {
          setValue((state) => ({ ...state, loading: false }));
          return data;
        } else {
          refreshToken();
        }
      })
      .catch((err) => {
        console.error(err);
        refreshToken();
      });
  };

  const refreshToken = (): Promise<any> => {
    const token = btoa(`${appId}:`);

    const refreshToken = window.localStorage.getItem("refresh_token");

    if (!refreshToken || failedRefreshTokenRequestCount.current > 4) {
      logOut();
      throw new Error("Token error");
    }

    failedRefreshTokenRequestCount.current =
      failedRefreshTokenRequestCount.current + 1;
    return axios({
      url: `${base}token`,
      method: "POST",
      data: stringify({
        refresh_token: refreshToken,
        grant_type: "refresh_token",
      }),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: `Basic ${token}`,
      },
    })
      .then((data) => {
        window.localStorage.setItem("access_token", data.data.access_token);
        window.localStorage.setItem("refresh_token", data.data.refresh_token);
        // @ts-ignore
        window.gtag("config", process.env.REACT_APP_GA_ID, {
          user_id: data.data.memberId,
        });

        failedRefreshTokenRequestCount.current = 0;
        return refreshData();
      })
      .catch((err) => {
        console.error(err);
        logOut();
      });
  };

  const getTermsAndConditions = async (
    esSupplierOfferId: number
  ): Promise<string> => {
    const { data } = await axios({
      url: `${base}api/v2/ebservices/escatalogue/${esSupplierOfferId}/get-terms-conditions`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    });

    if (data?.meta?.status === "succeeded" && data?.content?.termsAndConditions)
      return data.content.termsAndConditions as string;
    else throw new Error("No data has been returned.");
  };

  const getCatalogue = (refresh?: boolean) => {
    return axios({
      url: `${base}api/v2/ebservices/escatalogue/${window.localStorage.getItem(
        "member_id"
      )}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    })
      .then((data) => {
        setValue((state) => ({
          ...state,
          data: {
            ...state.data,
            catalogue: {
              ...data.data.content,
              offers: data.data.content.catalogue.suppliers,
              categories: data.data.content.catalogue.categories,
              ids:
                data.data.content.catalogue.suppliers.map((i: Offer) =>
                  i.esSupplierId.toString()
                ) || [],
              cats: data.data.content.catalogue.categories
                .map((cat: any) => [
                  cat.categoryId.toString(),
                  cat.subCategories.map((c: any) => c.subCategoryId.toString()),
                ])
                .flat(3),
            },
          },
        }));
        return data;
      })
      .catch((err) => {
        console.error(err);
        if (refresh) {
          refreshToken();
        }
      });
  };

  const getIp = () => {
    publicIp.v4().then((ip) => {
      setUserIP(ip);
    });
  };

  const getMember = (refresh?: boolean) => {
    return axios({
      url: `${base}api/v2/ebservices/esmember/${window.localStorage.getItem(
        "member_id"
      )}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    })
      .then((data) => {
        setValue((state) => ({
          ...state,
          data: {
            ...state.data,
            member: data.data.content,
          },
        }));
        return data;
      })
      .catch((err) => {
        console.error(err);
        if (refresh) {
          refreshToken();
        }
      });
  };

  const getPayment = (refresh?: boolean) => {
    return axios({
      url: `${base}api/v2/ebservices/escart/${window.localStorage.getItem(
        "member_id"
      )}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    })
      .then((data) => {
        setValue((state) => ({
          ...state,
          data: {
            ...state.data,
            payment: {
              ...data.data.content,
            },
          },
        }));
        return data;
      })
      .catch((err) => {
        console.error(err);
        if (refresh) {
          refreshToken();
        }
      });
  };

  const updateAddress = (address: UpdateAddressParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esmember/${window.localStorage.getItem(
        "member_id"
      )}/update-delivery-address`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data: address,
    })
      .then(() => {
        return getPayment(true);
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => updateAddress(address));
      });
  };

  const deleteAddress = (address: DeleteAddressParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esmember/${window.localStorage.getItem(
        "member_id"
      )}/delete-delivery-address`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data: address,
    })
      .then(() => {
        return getPayment(true);
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => deleteAddress(address));
      });
  };

  const updateName = (data: UpdateNameParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esmember/${window.localStorage.getItem(
        "member_id"
      )}/update-member-name`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        return getMember(true);
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => updateName(data));
      });
  };

  const updatePassword = (
    data: UpdatePasswordParams,
    allowRefresh: boolean = true
  ): AxiosPromise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esmember/${window.localStorage.getItem(
        "member_id"
      )}/password-reset`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    }).catch((err) => {
      console.error(err);
      if (allowRefresh) {
        return refreshToken().then(() => updatePassword(data, false));
      }
      return err;
    });
  };

  const addFavourite = (id: string): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esfavourites`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data: {
        memberId: window.localStorage.getItem("member_id"),
        supplierId: id,
      },
    })
      .then(() => {
        // @ts-ignore
        window.gtag("event", "add_favourite", {
          event_category: "favourite",
          value: id,
        });

        setValue((state) => {
          if (!state.data.catalogue) {
            return state;
          }
          const updatedOffers = state.data.catalogue.offers?.map((item) => {
            if (item.esSupplierId.toString() === id) {
              item.isFavourite = true;
            }
            return item;
          });
          return {
            ...state,
            data: {
              ...state.data,
              catalogue: {
                ...state.data.catalogue,
                offers: updatedOffers,
              },
            },
          };
        });
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => addFavourite(id));
      });
  };

  const removeFavourite = (id: string): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esfavourites`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data: {
        memberId: window.localStorage.getItem("member_id"),
        supplierId: id,
      },
    })
      .then(() => {
        // @ts-ignore
        window.gtag("event", "remove_favourite", {
          event_category: "favourite",
          value: id,
        });

        setValue((state) => {
          if (!state.data.catalogue) {
            return state;
          }
          const updatedOffers = state.data.catalogue.offers?.map((item) => {
            if (item.esSupplierId.toString() === id) {
              item.isFavourite = false;
            }
            return item;
          });
          return {
            ...state,
            data: {
              ...state.data,
              catalogue: {
                ...state.data.catalogue,
                offers: updatedOffers,
              },
            },
          };
        });
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => removeFavourite(id));
      });
  };

  const subscribe = (data: SubscribeParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/${window.localStorage.getItem(
        "member_id"
      )}/subscribe`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        return getMember(true);
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => subscribe(data));
      });
  };

  const unSubscribe = (data: UnsubscribeParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/${window.localStorage.getItem(
        "member_id"
      )}/unsubscribe`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        return getMember(true);
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => unSubscribe(data));
      });
  };

  const updateCard = (data: UpdateCardParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esreloadablecard/${window.localStorage.getItem(
        "member_id"
      )}`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        setValue((state) => {
          if (!state.data.catalogue) {
            return state;
          }
          const updatedCards = state.data.catalogue.userCards?.map((item) => {
            if (item.cardId === data.cardId) {
              item = { ...item, ...data };
            }
            return item;
          });
          return {
            ...state,
            data: {
              ...state.data,
              catalogue: {
                ...state.data.catalogue,
                userCards: updatedCards,
              },
            },
          };
        });
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => updateCard(data));
      });
  };

  const updateEmail = (data: UpdateEmailParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esmember/${window.localStorage.getItem(
        "member_id"
      )}/update-email-request`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        return getMember(true);
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => updateEmail(data));
      });
  };

  const submitCompetition = (data: SubmitCompetitionParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esmember/${window.localStorage.getItem(
        "member_id"
      )}/competitionanswer`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        return getCatalogue();
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => submitCompetition(data));
      });
  };

  const getCineworld = (): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/${window.localStorage.getItem(
        "member_id"
      )}/cineworld`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => getCineworld());
      });
  };

  const getUniqodo = (code: string): Promise<any> => {
    return axios({
      url: `https://api.uniqodo.com/code/${process.env.REACT_APP_UNIQODO_TOKEN}/${code}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
      },
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => getUniqodo(code));
      });
  };

  const updateReminder = (data: UpdateReminderParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/estopupreminder/${window.localStorage.getItem(
        "member_id"
      )}`,
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        setValue((state) => {
          if (!state.data.catalogue) {
            return state;
          }
          const updatedCards = state.data.catalogue.userCards?.map((item) => {
            if (item.topUpReminderId === data.topUpReminderId) {
              item.topUpFrequencyTypeId = data.frequencyType;
              item.topUpFrequencyValue = data.frequencyValue;
              item.topUpReminderEmailAddress = data.Email;
              item.topUpAmount = data.Amount;
            }
            return item;
          });
          return {
            ...state,
            data: {
              ...state.data,
              catalogue: {
                ...state.data.catalogue,
                userCards: updatedCards,
              },
            },
          };
        });
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => updateReminder(data));
      });
  };

  const addReminder = (data: AddReminderParams): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/estopupreminder/${window.localStorage.getItem(
        "member_id"
      )}`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        // NOTE: we can't directly update the context because the topUpReminderId isn't in the response
        return getCatalogue(true);
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => addReminder(data));
      });
  };

  const deleteReminder = (data: { topUpReminderId: number }): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/estopupreminder/${window.localStorage.getItem(
        "member_id"
      )}`,
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then(() => {
        setValue((state) => {
          if (!state.data.catalogue) {
            return state;
          }
          const updatedCards = state.data.catalogue.userCards?.map((item) => {
            if (item.topUpReminderId === data.topUpReminderId) {
              item.topUpFrequencyTypeId = 0;
              item.topUpFrequencyValue = 0;
              item.topUpReminderEmailAddress = null;
              item.topUpAmount = 0;
              item.topUpReminderId = 0;
            }
            return item;
          });
          return {
            ...state,
            data: {
              ...state.data,
              catalogue: {
                ...state.data.catalogue,
                userCards: updatedCards,
              },
            },
          };
        });
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => deleteReminder(data));
      });
  };

  const placeOrder = (
    data: Order,
    allowRefresh: boolean = true
  ): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/escart/${window.localStorage.getItem(
        "member_id"
      )}/placeorder`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then((responseData) => {
        if (responseData && responseData.status === 200) {
          return responseData;
        } else {
          return refreshToken().then(() => placeOrder(data));
        }
      })
      .catch((err) => {
        console.error(err);
        if (allowRefresh) {
          return refreshToken().then(() => placeOrder(data, false));
        }
      });
  };

  const confirmOrder = (data: ConfirmationData) => {
    return axios({
      url: `${base}api/v2/ebservices/escart/${window.localStorage.getItem(
        "member_id"
      )}/confirm`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data,
    })
      .then((responseData) => {
        return responseData;
      })
      .catch((err) => {
        console.error(err);
        refreshToken().then(() => confirmOrder(data));
      });
  };

  const logActivity = (data: Activity) => {
    return axios({
      url: `${base}api/v2/ebservices/esuserappactivity`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data: {
        memberId: `${window.localStorage.getItem("member_id")}`,
        source: userIP,
        sessionId: (
          window.localStorage.getItem("access_token") || ""
        ).substring(0, 255),
        location: "internet",
        userAgent: window.navigator.userAgent,
        sourceId: 3,
        ...data,
      },
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        // refreshToken().then(() => logActivity(data));
      });
  };

  const userAcceptedCashbackTnCs = async (): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/cashback/${window.localStorage.getItem(
        "member_id"
      )}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => userAcceptedCashbackTnCs());
      });
  };

  const acceptCashbackTnCs = async (
    cashBackTermsVersion: string
  ): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/cashback/${window.localStorage.getItem(
        "member_id"
      )}/acceptTsCs`,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
      data: {
        hasAcceptedCashBackTsAndCs: true,
        termsVersion: cashBackTermsVersion,
      },
    })
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() =>
          acceptCashbackTnCs(cashBackTermsVersion)
        );
      });
  };

  const getOrderHistory = async (): Promise<any> => {
    return axios({
      url: `${base}api/v2/ebservices/esorderhistory/${window.localStorage.getItem(
        "member_id"
      )}`,
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
      },
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.error(err);
        return refreshToken().then(() => getOrderHistory());
      });
  };

  return (
    <UserContext.Provider
      value={{
        ...value,
        updateAddress,
        deleteAddress,
        updateName,
        updatePassword,
        addFavourite,
        removeFavourite,
        subscribe,
        unSubscribe,
        updateCard,
        updateEmail,
        refreshToken,
        submitCompetition,
        getCineworld,
        updateReminder,
        getTermsAndConditions,
        placeOrder,
        confirmOrder,
        getUniqodo,
        addReminder,
        deleteReminder,
        logActivity,
        userAcceptedCashbackTnCs,
        acceptCashbackTnCs,
        getOrderHistory,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useGetUser = () => {
  return useContext(UserContext);
};
