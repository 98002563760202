import React from "react";

export function BasketEmptyIcon(props: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 81 80"
      fill="none"
    >
      <path
        d="M18.8333 73.3334C17.5 73.3334 16.3333 72.8334 15.3333 71.8334C14.3333 70.8334 13.8333 69.6667 13.8333 68.3334V25C13.8333 23.6667 14.3333 22.5 15.3333 21.5C16.3333 20.5 17.5 20 18.8333 20H28V19.1667C28 15.6667 29.2083 12.7084 31.625 10.2917C34.0416 7.87502 37 6.66669 40.5 6.66669C44 6.66669 46.9583 7.87502 49.375 10.2917C51.7916 12.7084 53 15.6667 53 19.1667V20H62.1666C63.5 20 64.6666 20.5 65.6666 21.5C66.6666 22.5 67.1666 23.6667 67.1666 25V68.3334C67.1666 69.6667 66.6666 70.8334 65.6666 71.8334C64.6666 72.8334 63.5 73.3334 62.1666 73.3334H18.8333ZM18.8333 68.3334H62.1666V25H53V32.5C53 33.2084 52.759 33.8021 52.2771 34.2813C51.7951 34.7604 51.1978 35 50.4854 35C49.7729 35 49.1805 34.7604 48.7083 34.2813C48.2361 33.8021 48 33.2084 48 32.5V25H33V32.5C33 33.2084 32.759 33.8021 32.2771 34.2813C31.7951 34.7604 31.1978 35 30.4854 35C29.7729 35 29.1805 34.7604 28.7083 34.2813C28.2361 33.8021 28 33.2084 28 32.5V25H18.8333V68.3334ZM33 20H48V19.1667C48 17.0556 47.2778 15.2778 45.8333 13.8334C44.3889 12.3889 42.6111 11.6667 40.5 11.6667C38.3889 11.6667 36.6111 12.3889 35.1666 13.8334C33.7222 15.2778 33 17.0556 33 19.1667V20Z"
        fill="#E0E0E0"
      />
      <path
        d="M33.8333 43.3334C33.8333 45.1743 32.3409 46.6667 30.5 46.6667C28.659 46.6667 27.1666 45.1743 27.1666 43.3334C27.1666 41.4924 28.659 40 30.5 40C32.3409 40 33.8333 41.4924 33.8333 43.3334Z"
        fill="#E0E0E0"
      />
      <path
        d="M53.8333 43.3334C53.8333 45.1743 52.3409 46.6667 50.5 46.6667C48.659 46.6667 47.1666 45.1743 47.1666 43.3334C47.1666 41.4924 48.659 40 50.5 40C52.3409 40 53.8333 41.4924 53.8333 43.3334Z"
        fill="#E0E0E0"
      />
      <path
        d="M40.5 51.9427C35.5429 51.9427 31.1768 54.463 28.6085 58.2965C27.7733 59.5432 28.7866 61.0573 30.2873 61.0573C31.0476 61.0573 31.737 60.6419 32.1922 60.0329C34.0831 57.5027 37.0874 55.849 40.5 55.849C43.9163 55.849 46.9029 57.5063 48.807 60.041C49.2617 60.6464 49.9492 61.0573 50.7063 61.0573C52.21 61.0573 53.2254 59.5401 52.3879 58.2912C49.8192 54.4606 45.4548 51.9427 40.5 51.9427Z"
        fill="#E0E0E0"
      />
    </svg>
  );
}
