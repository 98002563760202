import React from "react";

export function PiggyIcon(props: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24">
      <path
        d="M15 10c0-.55.45-1 1-1s1 .45 1 1-.45 1-1 1-1-.45-1-1zM8 9h5V7H8v2zm14-1.5v6.97l-2.82.94L17.5 21H12v-2h-2v2H4.5S2 12.54 2 9.5C2 6.46 4.46 4 7.5 4h5c.91-1.21 2.36-2 4-2a1.498 1.498 0 011.38 2.08c-.14.34-.26.73-.32 1.15l2.27 2.27H22zm-2 2h-1L15.5 6c0-.65.09-1.29.26-1.91-.97.25-1.76.97-2.09 1.91H7.5C5.57 6 4 7.57 4 9.5c0 1.88 1.22 6.65 2.01 9.5H8v-2h6v2h2.01l1.55-5.15 2.44-.82V9.5z"
        fill="currentColor"
      />
    </svg>
  );
}
