import { makeStyles, Snackbar, Theme } from "@mui/material";
import { Alert } from "@mui/material";
import { css } from "@emotion/css";

const classes = {
  paper: css({
    zIndex: 9999,
    border: "1px solid #c3c3c3",
    borderRadius: "7px",
  }),
};

export function Toast({
  text,
  open = false,
  onClose,
  autoHideDuration = 2000,
  vertical = "top",
  horizontal = "center",
  type = "success",
}: {
  text?: string;
  open?: boolean;
  onClose?: () => void;
  autoHideDuration?: number;
  vertical?: "top" | "bottom";
  horizontal?: "left" | "right" | "center";
  type?: "error" | "warning" | "info" | "success";
}) {
  return (
    <Snackbar
      className={classes.paper}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
    >
      <Alert onClose={onClose} severity={type}>
        {text}
      </Alert>
    </Snackbar>
  );
}
