import React, { useState } from "react";
import {
  Card as MUICard,
  CircularProgress,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Ratio } from "../Ratio";
import { InfiniteIcon, LightningIcon } from "../Icons";
import { FavoriteBorderOutlined, Favorite } from "@mui/icons-material";
import { sizing } from "../../styles/sizing";
import { css } from "@emotion/css";
import theme from "../../styles/theme";

const classes = {
  root: css({
    maxWidth: "100%",
    transition: "opacity 0.4s ease",
    "&:hover": {
      opacity: 0.7,
    },
  }),
  imageContainer: css({
    position: "relative",
  }),
  logoImage: css({
    position: "absolute",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    width: "20%",
    opacity: 0.8,
    background: "white",
    borderRadius: theme.shape.borderRadius,
  }),
  link: css({
    textDecoration: "none",
    display: "inline-block",
    width: "100%",
  }),
  iconContainer: css({
    position: "absolute",
    top: theme.spacing(1),
    left: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  icon: css({
    borderRadius: theme.shape.borderRadius,
    opacity: 0.8,
    background: "white",
    width: "24px",
    height: "24px",
    padding: theme.spacing(0.25),
    maxWidth: "32px",
    maxHeight: "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: theme.spacing(1),
  }),
  iconText: css({
    borderRadius: theme.shape.borderRadius,
    opacity: 0.8,
    background: "white",
    padding: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& p": {
      lineHeight: sizing(12),
      fontSize: sizing(12),
    },
  }),
  text: css({
    paddingTop: theme.spacing(1.5),
    "& h6": {
      paddingBottom: theme.spacing(1),
    },
  }),
  iconbutton: css({
    width: "32px",
    height: "32px",
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    padding: theme.spacing(0),
    background: "white",
    "&:hover": {
      background: "white",
    },
  }),
  loader: css({
    width: "24px !important",
    height: "24px !important",
  }),
};

export function Card({
  image,
  title,
  subTitle,
  logoImage,
  to,
  isNew,
  isSelling,
  isReloadable,
  inStore,
  onFavourite,
  isFavourite,
  ariaImageLabel,
}: {
  image: string;
  title: string;
  subTitle?: string;
  logoImage?: string;
  to: string;
  isNew?: boolean;
  isSelling?: boolean;
  isReloadable?: boolean;
  inStore?: boolean;
  onFavourite?: () => Promise<void>;
  isFavourite?: boolean;
  ariaImageLabel: string;
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Link to={to} className={classes.link}>
      <MUICard elevation={0} className={classes.root}>
        <div className={classes.imageContainer}>
          <Ratio rounded ratio="3:2" image={image} aria={ariaImageLabel} />
          {logoImage && (
            <div className={classes.logoImage}>
              <Ratio
                rounded
                contain
                ratio="1:1"
                image={logoImage}
                aria={title}
              />
            </div>
          )}
          {(isNew || isSelling || isReloadable) && (
            <div className={classes.iconContainer}>
              {isSelling && (
                <div className={classes.icon}>
                  <LightningIcon height={24} />
                </div>
              )}
              {isReloadable && (
                <div className={classes.icon}>
                  <InfiniteIcon width={24} />
                </div>
              )}
              {isNew && (
                <div className={classes.iconText}>
                  <Typography>New</Typography>
                </div>
              )}
              {inStore && (
                <div className={classes.iconText}>
                  <Typography>In Store-only</Typography>
                </div>
              )}
            </div>
          )}
          {onFavourite && (
            <IconButton
              onClick={(e) => {
                if (!isLoading) {
                  setIsLoading(true);
                  onFavourite().then(() => {
                    setIsLoading(false);
                  });
                  e.preventDefault();
                }
              }}
              color="primary"
              aria-label="Favoutrite this brand"
              className={classes.iconbutton}
              size="large"
            >
              {!isFavourite && !isLoading && (
                <FavoriteBorderOutlined width={24} />
              )}
              {isFavourite && !isLoading && <Favorite width={24} />}
              {isLoading && <CircularProgress className={classes.loader} />}
            </IconButton>
          )}
        </div>
        <div className={classes.text}>
          {title && <Typography variant="subtitle2">{title}</Typography>}
          {subTitle && (
            <Typography variant="body2" gutterBottom>
              {subTitle}
            </Typography>
          )}
        </div>
      </MUICard>
    </Link>
  );
}
