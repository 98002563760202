import React from "react";
import { Divider, Grid, Hidden, IconButton, Typography } from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { SecondaryButton } from "../Button";
import { ExitIcon } from "../Icons";
import { classes } from "./styles";

export function AccountPanel({
  onClose,
  onLogout,
  links,
  copy,
}: accountPanelNavigationProps) {
  return (
    <Grid
      id="account-menu"
      container
      spacing={0}
      className={`${classes.accountPanel} `}
    >
      <Grid item xs={12}>
        <Hidden mdUp>
          <IconButton
            onClick={onClose}
            className={classes.iconButton}
            size="large"
          >
            <ChevronLeft color="primary" />
          </IconButton>
        </Hidden>
        <Typography variant="h5" gutterBottom className={classes.panelTitle}>
          {copy.title}
        </Typography>
        <Divider className={classes.divider} />
      </Grid>

      <Grid item xs={12}>
        <Grid
          container
          spacing={1}
          className={classes.linksList}
          direction="column"
        >
          {links.map((link, index) => (
            <Grid item key={`account-link--${index}`}>
              <NavLink
                key={`account-link--${index}`}
                to={link.url}
                className={({ isActive }) =>
                  `${classes.navLink}` + (isActive ? " active" : "")
                }
              >
                <Typography variant="h5">{link.title}</Typography>
                <Typography variant="caption">{link.caption}</Typography>
              </NavLink>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
        <SecondaryButton
          onClick={onLogout}
          className={classes.logoutButton}
          fullWidth
        >
          <ExitIcon width={24} style={{ marginRight: "1em" }} />
          {copy.logout}
        </SecondaryButton>
      </Grid>
    </Grid>
  );
}

export interface accountPanelNavigationProps {
  onClose: () => void;
  onLogout: () => void;
  links: {
    title: string;
    caption?: string;
    url: string;
    alt: string;
  }[];
  copy: {
    logout: string;
    title: string;
  };
}
