import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import React, { useContext } from "react";
import { parse } from "qs";
import { useNavigate, useLocation } from "react-router";
import { useGetUser } from "./userContext";
import { template, templateSettings } from "lodash";
import { formatCurrency } from "../utils/formatCurrency";
import { useGetCopy } from "../api/queries/copy";
import { composeResource } from "../utils/composeResource";

export const AlertContext = React.createContext<{}>({});

export const AlertProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const location = useLocation();
  const searchParams = parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const {
    data: { catalogue },
  } = useGetUser();

  const { data: copyData } = useGetCopy({ key: "global.alert" });
  const { getText } = composeResource(copyData);

  const alerts = {
    1: {
      message: getText("alert.1"),
      severity: "error",
    },
    2: {
      message: getText("alert.2"),
      severity: "error",
    },
    3: {
      message: getText("alert.3"),
      severity: "error",
    },
    4: {
      message: getText("alert.4"),
      severity: "info",
    },
    5: {
      message: getText("alert.5"),
      severity: "info",
    },
    6: {
      message: getText("alert.6"),
      severity: "error",
    },
    7: {
      message: getText("alert.7"),
      severity: "info",
    },
    8: {
      message: getText("alert.8"),
      severity: "info",
    },
    9: {
      message: getText("alert.9"),
      severity: "error",
    },
    10: {
      message: getText("alert.10"),
      severity: "error",
    },
    11: {
      message: getText("alert.11"),
      severity: "error",
    },
    12: {
      message: getText("alert.12"),
      severity: "error",
    },
    13: {
      message: getText("alert.13"),
      severity: "error",
    },
  };

  // @ts-ignore
  const alert = alerts[searchParams.alert?.toString()];
  const navigate = useNavigate();

  templateSettings.interpolate = /{{([\s\S]+?)}}/g;
  const temp = template(alert?.message || "");

  return (
    <AlertContext.Provider value={true}>
      <>
        {alert && (
          <Snackbar
            open
            autoHideDuration={10000}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            style={{ zIndex: 99999 }}
          >
            <Alert
              severity={alert.severity}
              onClose={() => navigate({ search: "" })}
            >
              {temp({
                minimum: formatCurrency(catalogue?.contract.minimumOrder || 10),
              })}
            </Alert>
          </Snackbar>
        )}
        {children}
      </>
    </AlertContext.Provider>
  );
};

export const useGetAlerts = (): any => {
  return useContext(AlertContext);
};
