import { Box, CircularProgress } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

export function PageLoader() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
      bgcolor={grey[300]}
      padding={6}
      flex={1}
    >
      <CircularProgress />
    </Box>
  );
}
