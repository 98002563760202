import { copy } from "../types/generated/copy";

export interface LinkSet {
  label: string;
  ariaLabel: string;
  caption?: string;
  url: string;
  sys: {
    id: string;
  };
}

export interface CategorySet {
  title: string;
  mmiId: string;
  order: string;
  slug: string;
  image: {
    url: string;
    title: string;
    description?: string;
  };
}

export interface OfferSet {
  logo: {
    url: string;
  };
  featuredImagesCollection: {
    items: {
      url: string;
      title: string;
      description?: string;
    }[];
  };
  sys: {
    id: string;
  };
  name: string;
  mmiId: string;
}

export interface AssetSet {
  url: string;
  title: string;
  description?: string;
}

export const composeResource = (data: copy | undefined, ids?: string[]) => {
  let resources = {
    copy: {},
    linkSet: {},
    assetSet: {},
    categorySet: {},
    offerSet: {},
    promotionalSet: {},
  };

  if (
    data &&
    data.resourceSetCollection?.items.length &&
    data.resourceSetCollection?.items[0]
  ) {
    const base = data.resourceSetCollection?.items[0];
    resources = {
      copy:
        base.resourcesCollection?.items
          .filter((item) => item?.__typename === "Resource")
          .reduce((acc, cur) => {
            // @ts-ignore
            acc[cur.key] = cur.value;
            return acc;
          }, {}) || {},
      linkSet:
        base?.resourcesCollection?.items
          .filter((item) => item?.__typename === "LinkSet")
          .reduce((acc, cur) => {
            // @ts-ignore
            acc[cur.key] = cur.linksCollection.items;
            return acc;
          }, {}) || {},
      categorySet:
        base?.resourcesCollection?.items
          .filter((item) => item?.__typename === "CategorySet")
          .reduce((acc, cur) => {
            // @ts-ignore
            acc[cur.key] = cur.categoriesCollection.items;
            return acc;
          }, {}) || {},
      offerSet:
        base?.resourcesCollection?.items
          .filter((item) => item?.__typename === "OfferSet")
          .reduce((acc, cur) => {
            // @ts-ignore
            acc[cur.key] = cur.offersCollection.items.filter((i) =>
              (ids || []).includes(i.mmiId)
            );
            return acc;
          }, {}) || {},
      promotionalSet:
        base?.resourcesCollection?.items
          .filter((item) => item?.__typename === "PromotionalSet")
          .reduce((acc, cur) => {
            // @ts-ignore
            acc[cur.key] = cur.promotionsCollection.items.filter((i) =>
              (ids || []).includes(i.offer.mmiId)
            );
            return acc;
          }, {}) || {},
      assetSet:
        base?.resourcesCollection?.items
          .filter((item) => item?.__typename === "AssetSet")
          .reduce((acc, cur) => {
            // @ts-ignore
            acc[cur.key] = cur.asset;
            return acc;
          }, {}) || {},
    };
  }

  const getText = (key: string): string => {
    // @ts-ignore
    return resources.copy[key] || "";
  };

  const getLinkSet = (key: string): LinkSet[] => {
    // @ts-ignore
    return resources.linkSet[key] || [];
  };

  const getCategorySet = (key: string): CategorySet[] => {
    // @ts-ignore
    return resources.categorySet[key] || [];
  };

  const getOfferSet = (key: string): OfferSet[] => {
    // @ts-ignore
    return resources.offerSet[key] || [];
  };

  const getAssetSet = (key: string): AssetSet | null => {
    // @ts-ignore
    return resources.assetSet[key] || null;
  };

  const getPromotionalSet = (
    key: string
  ): { offer: OfferSet; image: AssetSet; title: string }[] => {
    // @ts-ignore
    return resources.promotionalSet[key] || null;
  };

  return {
    getText,
    getLinkSet,
    getCategorySet,
    getOfferSet,
    getAssetSet,
    getPromotionalSet,
  };
};
