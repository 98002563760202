import React from "react";

export function ExitIcon(props: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) {
  return (
    <svg {...props} fill="none" viewBox="0 0 21 18">
      <path
        d="M15.9907 4L14.5807 5.41L17.1607 8H6.99072V10H17.1607L14.5807 12.58L15.9907 14L20.9907 9L15.9907 4ZM2.99072 2H10.9907V0H2.99072C1.89072 0 0.990723 0.9 0.990723 2V16C0.990723 17.1 1.89072 18 2.99072 18H10.9907V16H2.99072V2Z"
        fill="currentColor"
      />
    </svg>
  );
}
