import React from "react";

export function BasketArrowIcon(props: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 16"
      fill="none"
    >
      <path
        d="M0 16L13.0104 1.36334C14.6015 -0.426717 17.3985 -0.426717 18.9896 1.36334L32 16H0Z"
        fill="#F4F7F9"
      />
    </svg>
  );
}
