import React from "react";

export function InfiniteIcon(props: {
  width?: number;
  height?: number;
  style?: React.CSSProperties;
}) {
  return (
    <svg {...props} fill="none" viewBox="0 0 23 12">
      <path
        d="M18.0082 11.25C20.6227 11.25 22.75 8.89503 22.75 6C22.75 3.10497 20.6227 0.75 18.0082 0.75C15.1756 0.75 12.7154 2.83157 11.4997 4.42838C10.284 2.83157 7.8238 0.75 4.99125 0.75C2.37732 0.75 0.25 3.10497 0.25 6C0.25 8.89503 2.37732 11.25 4.99125 11.25C7.8238 11.25 10.284 9.16782 11.4997 7.57224C12.7154 9.16782 15.1756 11.25 18.0082 11.25ZM18.0082 2.59318C19.7049 2.59318 21.0854 4.12118 21.0854 6C21.0854 7.87882 19.7049 9.40682 18.0082 9.40682C15.2561 9.40682 13.1255 6.92589 12.489 6.00553C13.1427 5.05813 15.2761 2.59318 18.0082 2.59318ZM4.99125 9.40682C3.29505 9.40682 1.91457 7.87882 1.91457 6C1.91457 4.12118 3.29505 2.59318 4.99125 2.59318C7.74279 2.59318 9.87344 5.07411 10.5104 5.99447C9.85735 6.94125 7.72392 9.40682 4.99125 9.40682Z"
        fill="currentColor"
      />
    </svg>
  );
}
