import { Catalogue } from "../contexts/userContext";

export const getDiscountTitle = (
  mmiId: string | undefined | null,
  catalogue?: Catalogue
) => {
  const offer = catalogue?.offers?.find((offer) => {
    return offer.esSupplierId.toString() === mmiId;
  });

  return `${
    offer?.ssvRetailerVouchers
      ? offer?.ssvRetailerVouchers[0]?.discountPct
      : offer?.esDiscountPercentage
  }% off`;
};

export const getDiscount = (
  mmiId: string | undefined | null,
  catalogue?: Catalogue
) => {
  const offer = catalogue?.offers?.find((offer) => {
    return offer.esSupplierId.toString() === mmiId;
  });

  return `${
    offer?.ssvRetailerVouchers
      ? offer?.ssvRetailerVouchers[0]?.discountPct
      : offer?.esDiscountPercentage
  }%`;
};
