import React from "react";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./Routes";
import { HelmetProvider } from "react-helmet-async";
import { ApolloProvider } from "@apollo/client";
import { client } from "./client";
import { UserProvider } from "./contexts/userContext";
import { CartProvider } from "./contexts/cartContext";
import { AlertProvider } from "./contexts/alertContext";
import ScrollToTop from "./utils/ScrollToTop";
import { useLoadAppInsights } from "./utils/hooks/useLoadAppInsights";

function App() {
  useLoadAppInsights();

  return (
    <ApolloProvider client={client}>
      <Router>
        <ScrollToTop />
        <UserProvider>
          <CartProvider>
            <HelmetProvider>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <AlertProvider>
                    <Routes />
                  </AlertProvider>
                </ThemeProvider>
              </StyledEngineProvider>
            </HelmetProvider>
          </CartProvider>
        </UserProvider>
      </Router>
    </ApolloProvider>
  );
}

export default App;
