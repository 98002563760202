import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import { categorySearch } from "../../types/generated/categorySearch";

export const CATEGORY_SEARCH_QUERY = gql`
  query categorySearch($search: String!) {
    categoryCollection(
      limit: 4
      where: {
        OR: [{ title_contains: $search }, { description_contains: $search }]
      }
    ) {
      items {
        title
        description
        mmiId
        slug
        image {
          url
          title
          description
        }
      }
    }
  }
`;

export const useGetCategorySearch = (args: QueryHookOptions<categorySearch>) =>
  useQuery<categorySearch>(CATEGORY_SEARCH_QUERY, args);
