import { gql, useQuery } from "@apollo/client";
import { copy, copyVariables } from "../../types/generated/copy";

export const COPY_QUERY = gql`
  query copy($key: String) {
    resourceSetCollection(where: { name: $key }, limit: 1) {
      items {
        name
        resourcesCollection {
          items {
            ... on Resource {
              key
              value
              sys {
                id
              }
            }
            ... on LinkSet {
              key
              linksCollection(limit: 20) {
                items {
                  label
                  ariaLabel
                  caption
                  url
                  sys {
                    id
                  }
                }
              }
            }
            ... on OfferSet {
              key
              offersCollection(limit: 8) {
                items {
                  logo {
                    url
                    description
                  }
                  featuredImagesCollection(limit: 1) {
                    items {
                      url
                      title
                      description
                    }
                  }
                  sys {
                    id
                  }
                  name
                  mmiId
                }
              }
            }
            ... on CategorySet {
              key
              categoriesCollection(limit: 20) {
                items {
                  title
                  mmiId
                  order
                  slug
                  image {
                    url
                    title
                    description
                  }
                }
              }
            }
            ... on AssetSet {
              key
              asset {
                url
                title
                description
              }
            }
            ... on PromotionalSet {
              key
              promotionsCollection(limit: 6) {
                items {
                  offer {
                    logo {
                      url
                      description
                    }
                    featuredImagesCollection(limit: 1) {
                      items {
                        url
                        title
                        description
                      }
                    }
                    sys {
                      id
                    }
                    name
                    mmiId
                    inStore
                    isNew
                    fastSelling
                  }
                  title
                  image {
                    description
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetCopy = (args?: copyVariables) => {
  return useQuery<copy>(COPY_QUERY, { variables: { ...args } });
};
